<template>
  <div class="container">

<!--    <el-tabs-->
<!--            v-model="$store.state.activeIndex"-->
<!--            type="border-card"-->
<!--            closable-->
<!--            v-if="$store.state.openTab.length"-->
<!--            @tab-click='tabClick'-->
<!--            @tab-remove='tabRemove'-->
<!--    >-->
    <el-tabs
            v-model="$store.state.activeIndex"
            type="border-card"
            v-if="$store.state.openTab.length"
            @tab-click='tabClick'
            @tab-remove='tabRemove'
    >
      <!-- 获取vuex中的openTab数组数据，循环展示 -->
      <el-tab-pane
              :key="index"
              v-for="(item, index) in $store.state.openTab"
              :label="item.name"
              :name="item.route"
      >
        <template #label>
        <span class="custom-tabs-label" :id="'intro'+index">
          <span>{{item.name}}</span>
        </span>
        </template>
      </el-tab-pane>
    </el-tabs>


      <div class="content-wrap" style="height:100%">
          <!-- 展示路由内容 -->
          <keep-alive>
              <router-view/>
          </keep-alive>
      </div>

  </div>
</template>

<script>
import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
// import 'intro.js/themes/introjs-modern.css' // introjs主题
import {toolVersion} from '/src/api/request'

  export default {
    name: 'App',
    components: {
    },

    data() {
      return {
      }
    },
    created(){
    },
    activated(){
        // console.log('activated')
    },
    mounted(){
      this.visibilitychangeFun()
      this.checkVersion()
      // 刷新时以当前路由做为tab加入tabs
      // 当前路由不是首页时，添加首页以及另一页到store里，并设置**状态
      // 当当前路由是首页时，添加首页到store，并设置**状态
      // console.log(this.$route.path)
      if (this.$route.path !== '/' && this.$route.path !== '/otherIdNum') {

        // this.$store.commit('add_tabs', {route: '/' , name: '首页'});
        // //通过路由的判断，来加入标签页的名称
        // if(this.$route.path == "/home/userList"){
        //   this.$store.commit('add_tabs', {route: this.$route.path , name: "用户列表"});
        // }
        // if(this.$route.path == "/home/roleList"){
        //   this.$store.commit('add_tabs', {route: this.$route.path , name: "角色列表"});
        // }
        this.$store.commit('set_active_index', this.$route.path);
      } else {

        // this.$store.commit('add_tabs', {route: '/home', name: '证件号码生成'});
        this.$store.commit('set_active_index', '/otherIdNum');
        this.$router.push('/otherIdNum');
      }

    },
    watch:{
      '$route'(to,from){
        // console.log(`watch route ${to.path}`)
        //判断路由是否已经打开
        //已经打开的 ，将其置为active
        //未打开的，将其放入队列里
        let flag = false;
        for(let item of this.$store.state.openTab){
          if(item.route === to.path){
            this.$store.commit('set_active_index',to.path)
            flag = true;
            break;
          }
        }
        if(!flag){
          // console.log('watch.to.path',to.path);
          //通过路由的判断，来加入标签页的名称
          // if(to.path == "/home/userList"){
          //   this.$store.commit('add_tabs', {route: to.path, name: "用户列表"});
          // }

          this.$store.commit('set_active_index', to.path);
        }
      }
    },
    methods: {
      // 核对版本, 不对则刷新页面
      checkVersion(){
        var version = localStorage.getItem('version')
        this.$store.commit('setVersion', version);
        toolVersion({}, response => {
          if(version !== response.version){
            this.$store.commit('setVersion', response.version);
            localStorage.setItem('version', response.version)

            setTimeout(()=>{
              this.setGuide(response.version, response.version_log)
            }, 500)

            // if(response.version !== '1.0.0'){
            //   setTimeout(()=>{
            //     this.setGuide()
            //   }, 500)
            // }else{
            //   console.log('有刷')
            //   // 其他情况需强制刷新页面
            //   location.reload()
            // }
          }
        })

      },
      visibilitychangeFun(){
        var that = this
        document.addEventListener("visibilitychange", function () {
          if (document.visibilityState == "hidden") {
            //切离该页面时执行
          } else if (document.visibilityState == "visible") {
            //切换到该页面时执行
            that.checkVersion()
          }
        });
      },
      //tab标签点击时，切换相应的路由
      tabClick(tab){
        // console.log(`tabClick activeIndex ${this.$store.state.activeIndex}`)
        this.$router.push({path: this.$store.state.activeIndex});
      },
      //移除tab标签
      tabRemove(targetName){
        // console.log("tabRemove",targetName);
        //首页不删
        if(targetName == '/'){
          return
        }
        this.$store.commit('delete_tabs', targetName);
        if (this.$store.state.activeIndex === targetName) {
          // 设置删除后，重新**的路径
          if (this.$store.state.openTab && this.$store.state.openTab.length >= 1) {
            // console.log('=============',this.$store.state.openTab[this.$store.state.openTab.length-1].route)
            //设置路由展示，为索引前一个路由
            this.$store.commit('set_active_index', this.$store.state.openTab[this.$store.state.openTab.length-1].route);
            //跳转路由
            this.$router.push({path: this.$store.state.activeIndex});
          } else {
            //否则 跳转到首页
            this.$router.push({path: '/'});
          }
        }
      },

      // 使用指引
      setGuide(version, data = []) {
        if(data.length <= 0){
          return false
        }
        // let data = [];
        // if(version === '1.0.0'){
        //   data = [
        //     { element: '#intro0', title:'新增', intro: '(✪ω✪)贾女士: 丰富证件类型', position: 'bottom' },
        //     { element: '#intro1', title:'不变', intro: '原身份证生成', position: 'right' }
        //   ]
        // }else if (version === '1.0.2') {
        //   data = [
        //     { element: '#intro0', title:'调整', intro: '龚先生: 适应小屏幕布局', position: 'bottom' },
        //     { element: '#search_birth', title:'修复', intro: '郑工: 修复选择出生日期无法生效的问题', position: 'bottom' },
        //   ]
        // }else{
        //   return false
        // }
        intro().setOptions({
          steps: data, /*绑定数组data*/
          prevLabel: "上一步",
          nextLabel: "下一步",
          skipLabel: "跳过",
          doneLabel: "完成",
          tooltipPosition: 'bottom',/* 引导说明框相对高亮说明区域的位置 */
          tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
          highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
          exitOnEsc: true,/* 是否使用键盘Esc退出 */
          exitOnOverlayClick: true,/* 是否允许点击空白处退出 */
          showStepNumbers: false,/* 是否显示说明的数据步骤*/
          keyboardNavigation: true,/* 是否允许键盘来操作 */
          showButtons: true,/* 是否按键来操作 */
          showBullets: false,/* 是否使用点点点显示进度 */
          showProgress: false,/* 是否显示进度条 */
          scrollToElement: true,/* 是否滑动到高亮的区域 */
          overlayOpacity: 0.8, /* 遮罩层的透明度 */
          positionPrecedence: ["bottom", "top", "right", "left"],/* 当位置选择自动的时候，位置排列的优先级 */
          disableInteraction: true, /* 是否禁止与元素的相互关联 */
          hintPosition: 'bottom-middle', /* 默认提示位置 */
          hintButtonLabel: 'Got it'/* 默认提示内容 */
        }).oncomplete(() => {
          //点击结束按钮后执行的事件
        }).onexit(() => {
          //点击跳过按钮后执行的事件
        }).start()
      }

    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: black;
    height: 100vh;
  }
  .el-tabs--border-card > .el-tabs__content{
    padding: 0 !important;
  }
  .container{
    background: linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%);
    background-blend-mode: screen;

  }



</style>
