import axios from 'axios';
import config from '../config/index'; //这里config文件夹下只有index.js这一个文件,所以可以简写，若有多个文件，需写明是哪个文件，如:'@/config/index'
import pakoController from './pako_controller';

// 判断是开发环境下的baseUrl还是生产环境下的baseUrl
const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        // 请求队列
        this.queue = {};
    }

    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            header: {},
        }
        return config;
    }

    interceptors(instance, url) {
        // 拦截前端发出的请求
        instance.interceptors.request.use(config => {
            // 处理config
            // console.log("拦截请求", config);
            return config;
        })

        // 拦截后台做出的相应
        instance.interceptors.response.use(res => {
            // 处理响应
            // console.log("拦截响应", res);
            return res;
        }, err => {
            // 请求出问题，处理问题
            // console.log(err);
            return {err: "网络出错了"};
        })
    }

    request(options, ret) {
        const instance = axios.create();//创造实例对象
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        // console.log('request', options)
        instance(options).then(res => {
            ret(pakoController.inflateDecode(res.data.data))
        });
    }
}

const axiosObj = new HttpRequest(baseUrl);
export default axiosObj;
